@import 'src/styles/main.scss';
.lang {
  position: fixed;
  z-index: 50;
  right: 0;
  top: 0;
  margin: 5px;
  display: flex;
  justify-content: flex-end;
}

.selectButton {
  box-sizing: content-box;
  border: none;
  background-color: transparent;
  width: 20px;
  height: 20px;
  color: $primary-color;
  font-size: 12px;
  border-radius: 50%;
  border: 1px solid $primary-color;
  background-color:$accent-color;
  padding: 1px 0 1px;
  cursor: pointer;
  &:focus,
  &:hover {
    color: $accent-color;
  // border: 1px solid $primary-color;
  background-color:$primary-color;
  border: 1px solid $accent-color;
  }
  &:disabled {
    opacity: 0;
    cursor: default;
  }
}


@media screen and (min-width:$mobile){
    .selectButton{
    font-size: 14px;
  }
}

@media screen and (min-width:$tablet){
  .selectButton{
  font-size: 16px;
  padding: 2px 1px 2px;
  width: 30px;
  height: 30px;

}
}

@media screen and (min-width:$desktop){
  .selectButton{
  font-size: 18px;
}
}