@import 'src/styles/main.scss';

.modalSection{
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 500;
  display: none;
}


.modalBackdrop {
  // position: fixed;
  // top: 0;
  // left: 0;
  // z-index: 500;
  // background-color: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;
  animation: animate100 500ms linear ;
}

.modalContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // width: 65%;
  padding: 12px;
  background-color: rgba($color: #fff, $alpha: 1);
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  font-size: 12px;
  font-family: 'Raleway';
  letter-spacing: 1.1;
  line-height: 1.3;
  color: green;
}




@keyframes animate100 {
  0% {
    transform: scale(0);
  }

  30% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@media screen and (min-width: $mobile) {
  .modalContent {
  }
}

@media screen and (min-width: $tablet) {
  
  .modalSection{
    display: block;
  }
  .modalContent {
    width: 85%;
  }
}
@media screen and (min-width: $desktop) {
  .modalContent {
    width: 65%;
  }
}
