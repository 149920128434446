
.heroTitle {
    max-width: 320px;
    margin: 0 auto 45px;
    font-family: Play, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: calc(23 / 20);
    text-align: center;
    text-transform: uppercase;
    color:$primary-color ;
    margin: 0px auto 10px;
  }



  .heroSubtitle{
font-size: 14px;
font-style: italic;
font-weight: 600;
// color: green;
  }

@media screen and (min-width: $mobile ) {
  .heroTitle{
    max-width: 400px;
    font-size: 26px;

  }

  .heroSubtitle{
    font-size: 14px;
      }
}

@media screen and (min-width: $tablet) {
  .heroTitle {
      max-width: 580px;
      font-size: 40px;
      line-height: calc(33 / 28);
    }

    .heroSubtitle{
      font-size: 18px;
        }
}


@media screen and (min-width: $desktop) {
  .heroTitle {
      // font-family: Play, sans-serif;
      margin: 0px auto 125px;
      max-width: 1200px;
      font-size: 60px;
      line-height: calc(39 / 34);
      letter-spacing: 0.03rem;
    }

    .heroSubtitle{
      font-size: 20px;
        }
}