// variables

//, colors:
// $bgc-primary: rgba(143, 143, 65, 0.9);
$bgc-primary:rgba(143, 143, 65, 0.4);
$bgc-additional: #808580; 

$primary-color: rgb(7, 86, 7);
$secondary-color:#dcdc8f;
$accent-color: #dcdc8f;

//,icons:
$icons-primary-color: #afb1b8;

//,animation
$timing-function: cubic-bezier(0.4, 0, 0.2, 1);
$time-amount: 550ms;
$timeoutValue:1000ms;

//, breakpoints
$mobile: 480px; 
$tablet: 768px; 
$desktop: 1280px; 

